@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}

.for__ecm {
  display: none;
}

div.body-content.product__page{
  
  header.heard__top {
    //background-image: url('/assets/masthead-millionfun.svg');
    background-color: white;
    .logo__link {
      display: none;
      &.for__ecm {
        display: block;
      }
    }
    
    .logo__link img{
        margin-top: 0rem;
        height: 40px !important;
        @include media-breakpoint-up(md) { // 768px and
          height: 50px !important;
        }
    }

  }
}